import { PageTypes, Theme, ThemeContext } from "assets";
import { graphql } from "gatsby";
import { convertHero } from "molecules";
import { convertComponentList, PageLayout } from "organisms";
import PropTypes from "prop-types";
import React from "react";

const ResourcesPageTemplate = ({ data }) => {
  const resourcesData = data?.craftAPI?.entry;

  const children = convertComponentList(resourcesData?.componentList);

  // Place the hero at the top of the detail
  children.unshift(
    convertHero({
      activeNavItemId: resourcesData.id,
      navItems: resourcesData.parent.children,
      ...resourcesData.parent,
    })
  );

  return (
    <ThemeContext.Provider
      value={{
        theme: Theme.Black,
        fontType: "",
      }}
    >
      <PageLayout
        coverImage={resourcesData.parent?.coverImage}
        pageType={PageTypes.RESOURCES}
        shortDescription={resourcesData.parent?.shortDescription}
        theme={Theme.Black}
        title={resourcesData.title}
      >
        {children}
      </PageLayout>
    </ThemeContext.Provider>
  );
};

export const resourcesQuery = graphql`
  query ($uri: [String]) {
    craftAPI {
      entry(uri: $uri) {
        id
        ... on CraftAPI_resources_resourcesTopic_Entry {
          id
          title
          uri
          componentList {
            ...ComponentListFragment
          }
          parent {
            ... on CraftAPI_resources_resources_Entry {
              longDescription
              shortDescription
              title
              coverImage {
                ...ImageMetadataFragment
              }
              coverImageCropStyle
              coverImagePositionOverride
              heroType
              children {
                id
                uri
                title
              }
            }
          }
        }
      }
    }
  }
`;

ResourcesPageTemplate.propTypes = {
  data: PropTypes.shape({
    craftAPI: PropTypes.shape({
      entry: PropTypes.shape({}),
    }),
  }),
};

export default ResourcesPageTemplate;
